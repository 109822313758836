import React from "react";
import { Box, Text, Heading, Button } from "rebass/styled-components";
import Link from "../components/UI/Link";
import Layout from "../components/Layout";
import { useStaticQuery, graphql } from "gatsby";
import Icon from "../components/UI/Icon";
const Page404 = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { title: { eq: "siteinfos" } }) {
          frontmatter {
            page404 {
              title
              text
            }
          }
        }
      }
    `
  );
  const siteinfos = markdownRemark.frontmatter || {};
  return (
    <Layout title={siteinfos.page404.title}>
      <Box p={5}>
        <Text py={5} textAlign="center" fontSize="80px">
          404
          <Icon icon="home" size={80} />
        </Text>
        <Heading py={5} textAlign="center">
          {siteinfos.page404.title}
        </Heading>
        <Text textAlign="center">{siteinfos.page404.text}</Text>
        <Box py={5} textAlign="center">
          <Link href="/">
            <Button>Retour à l&apos;accueil</Button>
          </Link>
        </Box>
      </Box>
    </Layout>
  );
};
export default Page404;
